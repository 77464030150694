import { v4 as uuidv4 } from "uuid";
import tippy from "tippy.js";
import { updateUrlParams } from "./utils";

document.addEventListener("alpine:init", () => {
    /*    Alpine.data("formHandlers", () => ({
        loading: false,
        submitForm () {
            this.loading = true;
        }
    }));*/

    Alpine.data(
        "modalUI",
        (id, submitEvent = "on-modal-submit", noLivewire = 1) => ({
            noLivewire: noLivewire === 1,
            id: id,
            show: false,
            working: false,
            submitEvent: submitEvent,
            title: "...",
            init() {
                window.addEventListener("on-modal-show", (payload) => {
                    if (payload.detail.id !== this.id) return;

                    this.title = payload.detail.title ?? "...";
                    this.show = true;
                    if (payload.detail.working) {
                        this.working = payload.detail.working;
                    }

                    tippy("[data-tippy-content]", {
                        allowHTML: true,
                    });
                });

                window.addEventListener("on-modal-working", (payload) => {
                    this.working = payload.detail;
                });

                window.addEventListener("on-modal-hide", () => {
                    this.close();
                });

                window.addEventListener("on-modal-validation-fails", () => {
                    this.working = false;
                });
            },
            close() {
                this.show = false;
                this.working = false;

                if (!noLivewire) {
                    window.Livewire.emit("onModalHide");
                } else {
                    window.customEvent("on-modal-hided");
                }
            },
            submit() {
                this.working = true;

                if (!noLivewire) {
                    window.Livewire.emit("onSubmitModal");
                } else {
                    window.customEvent(this.submitEvent);
                }
            },
        }),
    );

    Alpine.data(
        "tagSelect",
        (tags = [], refName = "textInput", validateType = "email") => ({
            open: false,
            textInput: "",
            validateError: false,
            refName,
            tags,
            validateType,
            validateTag(tag) {
                let re =
                    this.validateType === "email"
                        ? /\S+@\S+\.\S+/
                        : /^((\+0?1)?)\(?\d{3}\)?\d{3}[\s.-]\d{4}$/g;
                return re.test(tag);
            },
            addTag(tag) {
                this.validateError = false;
                tag = tag.trim();
                if (this.validateTag(tag)) {
                    if (tag !== "" && !this.hasTag(tag)) {
                        this.tags.push(tag);
                    }
                    this.clearSearch();
                    this.$refs[this.refName].focus();
                    this.fireTagsUpdateEvent();
                } else {
                    this.validateError = true;
                }
            },
            fireTagsUpdateEvent() {
                this.$el.dispatchEvent(
                    new CustomEvent("tags-update", {
                        detail: { tags: this.tags },
                        bubbles: true,
                    }),
                );
            },
            hasTag(tag) {
                let _tag = this.tags.find((e) => {
                    return e.toLowerCase() === tag.toLowerCase();
                });
                return _tag !== undefined;
            },
            removeTag(index) {
                this.tags.splice(index, 1);
                this.fireTagsUpdateEvent();
            },
            search(q) {
                if (q.includes(",")) {
                    q.split(",").forEach(function (val) {
                        this.addTag(val);
                    }, this);
                }
                this.toggleSearch();
            },
            clearSearch() {
                this.textInput = "";
                this.toggleSearch();
            },
            toggleSearch() {
                this.open = this.textInput !== "";
            },
        }),
    );

    Alpine.data(
        "customAutocomplete",
        (
            initialApiRoute = "",
            objectTable = "",
            modelName = "",
            modelKey = "",
            modelID = "",
            initialItem = "",
            excludeFields = "",
            groupResponse = 0,
            required = 0,
            livewireAddEmit = "",
            livewireRemoveEmit = "",
            eventOnExclude = "",
        ) => ({
            apiRoute: initialApiRoute,
            model: {
                name: modelName,
                index: modelKey,
                uuid: modelID,
            },
            selectedItem: null,
            excludeFields: [],
            groupResponse: groupResponse,
            required: required,
            livewireAddEmit: livewireAddEmit,
            livewireRemoveEmit: livewireRemoveEmit,
            eventOnExclude: eventOnExclude,
            showResult: true,
            fetching: false,
            resultItems: [],
            cancel: null,
            focus: false,
            validationError: false,
            query: "",
            objectTable: [],
            markInstance: new window.$iMark("div.autocomplete-custom__result"),
            init() {
                this.objectTable = objectTable ? JSON.parse(objectTable) : [];

                if (this.model.name === "field") {
                    let parseExcludeFields = excludeFields
                        ? JSON.parse(excludeFields)
                        : null;
                    if (parseExcludeFields) {
                        this.excludeFields = Object.values(parseExcludeFields);
                    }

                    window.addEventListener(this.eventOnExclude, (payload) => {
                        this.excludeFields = payload.detail;
                    });
                }

                if (this.model.name === "resource") {
                    window.addEventListener("on-validation-fail", (payload) => {
                        if (this.model.uuid === payload.detail.uuid) {
                            this.validationError = true;
                        }
                    });
                }

                let parseItem = initialItem ? JSON.parse(initialItem) : null;
                if (parseItem) {
                    if (
                        parseItem.hasOwnProperty("column") ||
                        parseItem.hasOwnProperty("id") ||
                        parseItem.hasOwnProperty("groupTitle") ||
                        parseItem.hasOwnProperty("title")
                    ) {
                        this.selectedItem = parseItem;
                    } else {
                        this.query = parseItem.title ?? parseItem;
                    }
                }

                this.$watch("query", () => {
                    this.fetch();

                    if (this.model.name === "resource") {
                        if (this.query.length >= 1) {
                            window.Livewire.emit(this.livewireAddEmit, {
                                model: this.model,
                                item: {
                                    title: this.query,
                                },
                            });

                            this.validationError = false;
                        } else {
                            window.Livewire.emit(this.livewireRemoveEmit, {
                                model: this.model,
                            });

                            if (this.required) {
                                this.validationError = true;
                            }
                        }
                    }
                });

                window.addEventListener(
                    "autocomplete:clear:selectedItem",
                    () => {
                        this.selectedItem = null;
                    },
                );
            },
            setItem(item) {
                this.selectedItem = item;

                if (this.livewireAddEmit) {
                    window.Livewire.emit(this.livewireAddEmit, {
                        model: this.model,
                        item,
                    });
                }

                if (this.model.name === "resource" && this.required) {
                    this.validationError = false;
                }

                this.excludeFields.push(item.id);
                this.dispatchExcludeFields();

                this.onBlur();
            },
            dispatchExcludeFields() {
                window.customEvent(
                    this.eventOnExclude,
                    this.excludeFields.filter((item) => item),
                );
            },
            removeSelectedItem() {
                this.excludeFields = this.excludeFields.filter(
                    (item) => item !== this.selectedItem.id,
                );

                this.selectedItem = null;
                this.dispatchExcludeFields();

                window.Livewire.emit(this.livewireRemoveEmit, {
                    model: this.model,
                });

                if (this.model.name === "resource" && this.required) {
                    this.validationError = true;
                }
            },
            onFocus() {
                this.focus = true;
                this.fetch();
            },
            onBlur() {
                this.focus = false;
            },
            fetch() {
                this.fetching = true;

                if (this.cancel) {
                    this.cancel();
                }

                let params = {};

                if (this.objectTable) {
                    params.model =
                        this.objectTable instanceof Array
                            ? this.objectTable
                            : [this.objectTable];
                }

                if (this.query) {
                    params.query = this.query;
                }

                if (this.excludeFields.length) {
                    params.exclude = this.excludeFields.filter((item) => item);
                }

                window.$axios
                    .get(this.apiRoute, {
                        cancelToken: new window.$clearAxios.CancelToken((c) => {
                            this.cancel = c;
                        }),
                        params,
                    })
                    .then(({ data: response }) => {
                        let parseResponse = response;

                        if (this.model.name === "resource") {
                            if (Array.isArray(parseResponse)) {
                                parseResponse = parseResponse.filter(
                                    (item) => item.items.length,
                                );
                            }

                            if (parseResponse instanceof Object) {
                                parseResponse = [
                                    {
                                        title: "Functions",
                                        items: Object.values(
                                            parseResponse.functions,
                                        ).map((item) => {
                                            if (item instanceof Object) {
                                                return item;
                                            }

                                            return {
                                                title: item,
                                            };
                                        }),
                                    },
                                    {
                                        title: "Variables",
                                        items: Object.values(
                                            parseResponse.variables,
                                        ).map((item) => {
                                            if (item instanceof Object) {
                                                return item;
                                            }

                                            return {
                                                title: item,
                                            };
                                        }),
                                    },
                                ];
                            }
                        }

                        this.resultItems = parseResponse.map((item) => {
                            if (item.items) {
                                return {
                                    uuid: uuidv4(),
                                    title: item.title,
                                    items: item.items.map((_item) => ({
                                        ..._item,
                                        uuid: uuidv4(),
                                        groupTitle: item.title,
                                    })),
                                };
                            }
                            return {
                                uuid: uuidv4(),
                                ...item,
                            };
                        });

                        console.warn("this.resultItems", this.resultItems);

                        if (this.resultItems.length && this.query) {
                            setTimeout(() => {
                                this.markInstance.unmark({
                                    done: () => {
                                        this.markInstance.mark(this.query, {
                                            element: "strong",
                                        });
                                    },
                                });
                            }, 50);
                        }
                    })
                    .finally(() => {
                        this.fetching = false;
                    });
            },
        }),
    );

    Alpine.data("filterAutocomplete", (initialItem = null) => ({
        item: JSON.parse(initialItem),
        open: false,
        filterQuery: "",
        results: [],
        selected: [],
        fetching: false,
        init() {
            if (this.item.filterableOptions) {
                this.results = this.item.filterableOptions;

                if (
                    new URLSearchParams(location.search).get(
                        `filters[${this.item.key}]`,
                    )
                ) {
                    let urlFilter = new URLSearchParams(location.search)
                        .get(`filters[${this.item.key}]`)
                        .split(",");
                    this.selected = this.results.filter((item) =>
                        urlFilter.includes(item.value),
                    );
                } else if (this.item.filterableDefault) {
                    this.selected = this.results.filter(
                        (item) => item.value === this.item.filterableDefault,
                    );

                    updateUrlParams(
                        `filters[${this.item.key}]`,
                        this.formattedSelected,
                    );
                    this.updatedFilters(this.item.key, this.formattedSelected);
                }
            } else {
                this.$watch("filterQuery", () => {
                    this.fetchFilter();
                });

                this.$watch("open", () => {
                    if (this.open) {
                        if (!this.results.length) {
                            this.fetchFilter();
                        }
                    }
                });
            }

            if (
                !this.item.filterableOptions &&
                new URLSearchParams(location.search).get(
                    `filters[${this.item.key}]`,
                )
            ) {
                this.preFetchSelected(
                    new URLSearchParams(location.search)
                        .get(`filters[${this.item.key}]`)
                        .split(","),
                ).then(({ data: response }) => {
                    this.$nextTick(async () => {
                        this.selected = await response;
                    });
                });
            }
        },
        get filteredResults() {
            return this.results.filter(
                (option) =>
                    !this.selected.find(
                        (item) => item.value === option.value,
                    ) &&
                    option.text
                        .toLowerCase()
                        .includes(this.filterQuery.toLowerCase()),
            );
        },
        get formattedSelected() {
            return this.selected.map((item) => item.value);
        },
        preFetchSelected(ids) {
            return window.$axios.get(this.item.filterableApi, {
                cancelToken: new window.$clearAxios.CancelToken((c) => {
                    this.cancel = c;
                }),
                params: {
                    includedIds: ids,
                },
            });
        },
        fetchFilter() {
            this.fetching = true;

            let params = {};

            if (this.filterQuery) {
                params.query = this.filterQuery;
            }

            if (this.selected.length) {
                params.excludedIds = this.formattedSelected;
            }

            window.$axios
                .get(this.item.filterableApi, {
                    cancelToken: new window.$clearAxios.CancelToken((c) => {
                        this.cancel = c;
                    }),
                    params,
                })
                .then(({ data: response }) => {
                    this.$nextTick(async () => {
                        this.results = await response;
                    });
                })
                .finally(() => {
                    this.fetching = false;
                });
        },
        outsideHandler() {
            this.open = false;
        },
        openHandler() {
            this.open = !this.open;
        },
        setFilterOption(option) {
            this.selected.push(option);

            updateUrlParams(
                `filters[${this.item.key}]`,
                this.formattedSelected,
            );
            this.updatedFilters(this.item.key, this.formattedSelected);
        },
        removeFilterOption(option) {
            this.selected = this.selected.filter(
                (item) => item.value !== option.value,
            );

            if (this.selected.length) {
                updateUrlParams(
                    `filters[${this.item.key}]`,
                    this.formattedSelected,
                );
                this.updatedFilters(this.item.key, this.formattedSelected);
            } else {
                updateUrlParams(`filters[${this.item.key}]`, null);
                this.updatedFilters(this.item.key, null);
            }
        },
        updatedFilters(key, value) {
            window.customEvent("updated-filter", {
                key,
                value,
            });
        },
    }));

    Alpine.data("dynamicFieldForm", (initialItems = null, name = null) => ({
        items: JSON.parse(initialItems),
        name: name,
        addItem() {
            this.items.push("");
        },
        removeItem(index) {
            this.items.splice(index, 1);
        },
        getName(index) {
            return `${this.name}[${index}]`;
        },
        getValue(index) {
            return this.items[index];
        },
    }));

    Alpine.data(
        "additionalDataForm",
        (initialItems = null, nameKey = null, name = null) => ({
            items: JSON.parse(initialItems),
            nameKey: nameKey,
            name: name,
            addItem() {
                this.items.push({
                    [this.nameKey]: "",
                    description: "",
                });
            },
            removeItem(index) {
                this.items.splice(index, 1);
            },
            getNameFirst(index) {
                return `${this.name}[${index}][${this.nameKey}]`;
            },
            getNameSecond(index) {
                return `${this.name}[${index}][description]`;
            },
            getValueFirst(index) {
                return this.items[index][`${this.nameKey}`];
            },
            getValueSecond(index) {
                return this.items[index]["description"];
            },
        }),
    );

    Alpine.data("confirmModal", (noLivewire = false) => ({
        noLivewire: noLivewire === 1,
        open: false,
        actionDetail: null,
        modalConfig: {
            title: "",
            description: "",
        },
        deleting: false,
        init() {
            window.addEventListener("on-modal-confirm", (payload) => {
                this.modalConfig = payload.detail.modal;
                this.actionDetail = payload.detail.data;
                this.open = true;
            });

            window.addEventListener("on-modal-confirm-hide", () => {
                this.hideModal();
            });
        },
        callModal($event) {
            this.modalConfig = $event.detail.modal;
            this.actionDetail = $event.detail;
            this.open = true;
        },
        hideModal() {
            this.open = false;
            this.deleting = false;

            if (!noLivewire) {
                this.$dispatch("update-listing");
            }
        },
        cancel() {
            this.open = false;
        },
        confirm() {
            this.deleting = true;

            if (!noLivewire) {
                this.$dispatch("action-item", this.actionDetail);
            } else {
                window.customEvent(
                    "on-modal-confirm:confirm",
                    this.actionDetail,
                );
            }
        },
    }));
});
