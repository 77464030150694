import {updateUrlParams} from "./utils";

document.addEventListener("alpine:init", () => {
    Alpine.data("datatables", (
        initialApiRoute = "/fetch",
        initialHeadings = [],
        initialTableName = null,
        relations = null,
        model = null
    ) => ({
        tableName: initialTableName,
        relations: relations,
        model: model,
        cancel: null,
        headings: initialHeadings,
        resultItems: [],
        selectedRows: [],
        apiRoute: initialApiRoute,
        search: new URLSearchParams(location.search).get("query") || "",
        searchPlaceholder: "",
        pagination: {
            total: 0,
            currentPage: parseInt(new URLSearchParams(location.search).get("page")) || 1,
            perPage: parseInt(new URLSearchParams(location.search).get("per-page")) || 10
        },
        totals: [],
        sorted: {},
        fetching: true,
        selectedAll: false,
        showFilters: false,
        fetchedOnce: false,
        filtersHead: initialHeadings.filter(item => item.filterable).map(item => {
            return {
                field: item.key,
                label: item.value,
                type: typeof item.filterable === "object" ? "select" : item.filterable,
                options: typeof item.filterable === "object" ? item.filterable : null,
                instance: null
            };
        }),
        filters: {},
        init () {
            let defaultSort = initialHeadings.find(item => typeof item.sortable === "string");

            this.sorted = {
                field: new URLSearchParams(location.search).get("sort") || defaultSort && defaultSort.key || "",
                direction: new URLSearchParams(location.search).get("direction") || defaultSort && defaultSort.sortable || ""
            };

            let searchableColumns = this.headings.filter(item => item.searchable);
            if (searchableColumns.length) {
                this.searchPlaceholder = `Search in ${searchableColumns.map(item => item.value).join(", ")}`;
            }

            this.showFilters = this.headings.filter(item => item.filterableDefault).length;

            if (this.filtersHead.length) {
                this.filtersHead.forEach(filter => {
                    let getParam = new URLSearchParams(location.search).get(`filters[${filter.field}]`);
                    this.filters[filter.field] = getParam ? getParam.split(",") : null;

                    if (filter.type === "datepicker") {
                        setTimeout(() => {
                            let oneFilter = this.filtersHead.find(item => item.field === filter.field);

                            if (oneFilter) {
                                oneFilter.instance = window.flatpickr(`input[data-flatpickr-field="${filter.field}"]`, {
                                    enableTime: false,
                                    mode: "range",
                                    maxDate: "today",
                                    dateFormat: window.settingAppJs.dateFormat,
                                    onChange: (selectedDates) => {
                                        if (selectedDates.length === 2) {
                                            this.setFilter(filter.field, selectedDates.map(item => flatpickr.formatDate(item, window.settingAppJs.dateFormat)));
                                        }
                                    }
                                });

                                let filterDates = new URLSearchParams(location.search).get(`filters[${filter.field}]`) ? new URLSearchParams(location.search).get(`filters[${filter.field}]`).split(",") : [];
                                oneFilter.instance.setDate(filterDates);
                            }
                        }, 100);
                    }
                });
                if (!this.showFilters) {
                    this.showFilters = Object.values(this.filters).filter(item => item).length;
                }
            }

            document.querySelector(".data-table .slack").classList.add("hidden");

            this.$watch("search", () => {
                updateUrlParams("query", this.search);
                this.runFetch();
            });

            this.$watch("pagination.perPage", () => {
                updateUrlParams("per-page", this.pagination.perPage);
                this.runFetch();
            });

            this.$watch("pagination.currentPage", () => {
                updateUrlParams("page", this.pagination.currentPage);
                this.fetch();
            });

            this.fetch();

            window.addEventListener("refresh-datatable", () => {
                this.runFetch();
            });

            window.addEventListener("updated-filter", (event) => {
                this.filters[event.detail.key] = event.detail.value;
                this.runFetch();
            });
        },
        runFetch () {
            if (this.pagination.currentPage > 1) {
                this.pagination.currentPage = 1;
            } else {
                this.fetch();
            }
        },
        clearSearch () {
            this.search = "";
            updateUrlParams("query");
            this.runFetch();
        },
        setFilter (field, values = null) {
            if (values && typeof values === "object") {
                updateUrlParams(`filters[${field}]`, values);
                this.filters[field] = values;
            } else if (this.filters[field]) {
                updateUrlParams(`filters[${field}]`, this.filters[field]);
            }
            this.runFetch();
        },
        clearFilter (field) {
            let oneFilter = this.filtersHead.find(item => item.field === field);
            if (oneFilter && oneFilter.type === "datepicker") {
                oneFilter.instance.clear();
            }

            this.filters[field] = null;
            updateUrlParams(`filters[${field}]`);
            this.runFetch();
        },
        toggleColumn ($event) {
            let allCells = document.querySelectorAll(`[data-cell-id="${$event.detail.key}"]`);

            if ($event.detail.hidden) {
                document.querySelector(`[data-head-id="${$event.detail.key}"]`).classList.add("hidden");
                allCells.forEach(item => {
                    item.classList.add("hidden");
                });
            } else {
                document.querySelector(`[data-head-id="${$event.detail.key}"]`).classList.remove("hidden");
                allCells.forEach(item => {
                    item.classList.remove("hidden");
                });
            }
        },
        selectAll ($event) {
            let checkboxes = document.querySelectorAll(".table-row-loop .row-checkbox input[type=\"checkbox\"]");

            this.selectedRows = [];

            if ($event.target.checked) {
                checkboxes.forEach(checkbox => {
                    checkbox.checked = true;
                    this.selectedRows.push(checkbox.name);
                });
            } else {
                checkboxes.forEach(checkbox => {
                    checkbox.checked = false;
                });
                this.selectedRows = [];
            }
        },
        selectCheckbox ($event) {
            if ($event.target.checked) {
                this.selectedRows.push($event.target.name);
            } else {
                this.selectedRows.splice(this.selectedRows.findIndex(item => item === $event.target.name), 1);
            }

            document.querySelector("input[type=\"checkbox\"][data-checked-all]").checked = this.resultItems.length === this.selectedRows.length;
        },
        callRemove (apiRoute) {
            this.$dispatch("call-confirm", {
                type: "delete",
                apiRoute,
                modal: {
                    title: "Delete",
                    description: "Are you sure?"
                }
            });
        },
        livewireEvent (livewireEvent, data) {
            window.Livewire.emit(livewireEvent, data);
        },
        callConfirm (action) {
            this.$dispatch("call-confirm", {
                action: "mass-action",
                params: action.params,
                apiRoute: action.apiRoute,
                modal: {
                    title: action.modalConfig.title,
                    description: action.modalConfig.description
                }
            });
        },
        callEvent (event, detail) {
            window.customEvent(event, detail);
        },
        actionItem ($event) {
            if ($event.detail.action === "mass-action") {
                let params = {
                    ids: $event.detail.params && $event.detail.params.ids ? $event.detail.params.ids : this.selectedRows,
                    ...$event.detail.params
                };

                window.$axios.post($event.detail.apiRoute, params).finally(() => {
                    this.$dispatch("hide-confirm");
                });
            } else {
                if ($event.detail.type === "delete") {
                    window.$axios.delete($event.detail.apiRoute).finally(() => {
                        this.$dispatch("hide-confirm");
                    });
                }
            }
        },
        forceUpdate () {
            this.fetch();
        },
        setSort (heading) {
            if (heading.sortable) {
                this.sorted.field = heading.key;
                this.sorted.direction = !this.sorted.direction ? "asc" : this.sorted.direction === "asc" ? "desc" : "asc";

                updateUrlParams("sort", heading.key);
                updateUrlParams("direction", this.sorted.direction);

                this.fetch();
            }
        },
        fetch () {
            this.fetching = true;

            if (this.cancel) {
                this.cancel();
            }

            let params = {
                limit: this.pagination.perPage
            };

            if (this.relations && this.relations.field && this.relations.id) {
                params[this.relations.field] = [this.relations.id];
            }

            if (this.model && this.model.name && this.model.id) {
                params["model"] = this.model.name;
                params["modelId"] = this.model.id;
            }

            if (this.tableName) {
                params["table-name"] = this.tableName;
            }

            if (this.search) {
                params.query = this.search;
            }

            if (this.sorted.field && this.sorted.direction) {
                params.order = this.sorted.direction;
                params.orderKey = this.sorted.field;
            }

            if (this.pagination.currentPage > 1) {
                params.offset = (this.pagination.currentPage - 1) * this.pagination.perPage;
            }

            Object.keys(this.filters).forEach(filter => {
                if (this.filters[filter]) {
                    params[filter] = this.filters[filter];
                }
            });

            window.$axios.get(this.apiRoute, {
                cancelToken: new window.$clearAxios.CancelToken((c) => {
                    this.cancel = c;
                }),
                params
            }).then(({data: response}) => {
                document.querySelectorAll(".row-checkbox input[type=\"checkbox\"]").forEach(item => {
                    item.checked = false;
                });

                this.$nextTick(async () => {
                    this.selectedRows = [];
                    this.resultItems = await response.data;
                    this.pagination.total = await response.count;
                    if (response.totals) {
                        this.totals = await response.totals;
                    }
                });
            }).finally(() => {
                this.fetchedOnce = true;
                this.fetching = false;
            });
        },
        rowSelected (id) {
            return this.selectedRows.includes(id);
        },
        prevPage () {
            --this.pagination.currentPage;
        },
        nextPage () {
            ++this.pagination.currentPage;
        },
        goToPage (page) {
            if (typeof page === "number") {
                this.pagination.currentPage = page;
            }
        },
        checkDatepicker (field) {
            return this.filters[field] && (
                (typeof this.filters[field] === "object" && this.filters[field].length === 2) ||
                (typeof this.filters[field] === "string" && this.filters[field].includes(" to "))
            );
        },
        get pages () {
            let range = (lo, hi) => Array.from({length: hi - lo}, (_, i) => i + lo);
            let count = 5;
            let page = this.pagination.currentPage;
            let total = Math.ceil(this.pagination.total / this.pagination.perPage);

            const start = Math.max(1, Math.min(page - Math.floor((count - 3) / 2), total - count + 2));
            const end = Math.min(total, Math.max(page + Math.floor((count - 2) / 2), count - 1));

            return [
                ...(start > 2 ? [1, "..."] : start > 1 ? [1] : []),
                ...range(start, end + 1),
                ...(end < total - 1 ? ["...", total] : end < total ? [total] : [])
            ];
        },
        get resultNotFound () {
            return !this.fetching && !this.resultItems.length;
        },
        get resultPagination () {
            let totalPerPage = this.pagination.currentPage * this.pagination.perPage;

            let to = this.pagination.currentPage === 1 ? 1 : ((this.pagination.currentPage - 1) * this.pagination.perPage) + 1;
            let from = this.pagination.currentPage === 1 ? totalPerPage > this.pagination.total ? this.pagination.total : totalPerPage : totalPerPage < this.pagination.total ? totalPerPage : this.pagination.total;

            return `Results ${to} - ${from} of ${this.pagination.total}`;
        }
    }));

    Alpine.data("hideColumns", (initialHeadings = []) => ({
        open: false,
        headings: initialHeadings.map(item => ({
            ...item,
            hidden: false,
            disabled: false
        })),
        toggleColumn ($event, key) {
            let column = this.headings.find(column => column.key === key);
            if (column) {
                this.$dispatch("toggle-column", {
                    key,
                    hidden: !$event.target.checked
                });

                column.hidden = !$event.target.checked;
            }

            if (this.headings.filter(item => !item.hidden).length === 1) {
                let alone = this.headings.find(item => !item.hidden);
                if (alone) {
                    alone.disabled = true;
                }
            } else {
                let alone = this.headings.find(item => item.disabled);
                if (alone) {
                    alone.disabled = false;
                }
            }
        }
    }));

    Alpine.data("dropdownAction", () => ({
        open: false,
        addVariableEmit (item, name) {
            window.customEvent("added-variable-tags", {
                item,
                name
            });
        },
        runAction (action = []) {
            this.$dispatch("call-confirm", {
                action: "mass-action",
                params: action.params ?? {},
                apiRoute: action.apiRoute,
                modal: {
                    title: action.modalConfig.title,
                    description: action.modalConfig.description
                }
            });
        }
    }));
});
